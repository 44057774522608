import React, { useEffect, useRef } from "react";
import { Image, Transformer } from "react-konva";
import useImage from 'use-image';

const URLImage = ({ url, isSelected, onSelect =() =>{}, x,  ...rest }) => {
  const [img] = useImage(url);
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      shapeRef.current.position({x: x, y: 0});
      trRef.current.setNode(shapeRef.current);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Image
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        draggable={isSelected}
        image={img}
        x={x}
        {...rest}
      />
      {isSelected && <Transformer ref={trRef} />}
    </>
  );
};

export default URLImage;