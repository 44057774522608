import React, { useEffect, useRef, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import "./App.css";
import "./assets/css/button.css";
import logo from "./assets/images/logo.png";

import { Layer, Stage } from "react-konva";
import URLImage from "./URLImage";
import { dataURItoBlob } from "./utility";
import Switch from "react-switch";

const capLists = ["cap.png"];

function App() {
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedCap, setSelectedCap] = useState();
    const [selectedId, selectShape] = useState(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [orgImgSize, setOrgImgSize] = useState({ width: 0, height: 0 });
    const [boxHeight, setBoxHeight] = useState(0);
    const [isFit, setIsFit] = useState(false);
    const containerRef = useRef();
    const stageRef = useRef();

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                let img = new Image();
                img.src = reader.result;
                setSelectedImage(reader.result);
                img.onload = function () {
                    setOrgImgSize({ width: this.width, height: this.height });
                    if (!isFit) {
                        const bHeight = (this.height / this.width) * containerRef.current.offsetWidth - 8;
                        setBoxHeight(bHeight);
                    }
                };
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSelectCap = (src) => {
        selectShape(0);
        setSelectedCap(src);
    };

    const exportImage = async () => {
        await selectShape(null);
        if (stageRef.current) {
            const dataURL = stageRef.current.toDataURL({ pixelRatio: 3 });
            let link = document.createElement("a");
            link.download = "beanie-pfp.png";
            link.href = dataURItoBlob(dataURL);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const remoteHat = async () => {
        setSelectedCap(null);
        selectShape(null);
    };

    useEffect(() => {
        if (isFit) {
            setBoxHeight(containerRef.current.offsetWidth - 8);
        } else {
            const bHeight = (orgImgSize.height / orgImgSize.width) * containerRef.current.offsetWidth - 8;
            setBoxHeight(bHeight);
        }
    }, [isFit]);

    useEffect(() => {
        containerRef.current.height = boxHeight;
        setDimensions({
            width: containerRef.current.offsetWidth - 8,
            height: boxHeight - 8,
        });
    }, [boxHeight]);

    useEffect(() => {
        setDimensions({
            width: containerRef.current.offsetWidth - 8,
            height: containerRef.current.offsetWidth - 8,
        });
    }, []);

    return (
        <div
            className="h-screen bg-no-repeat bg-cover overflow-auto"
            style={{
                background: "#0042fe",
            }}
        >
            <div className="px-8 relative">
                <div className="">
                    <div className="flex items-center">
                        <a href="/">
                            <img className="w-20 sm:w-24 pt-8 md:w-28 md:pt-5" src={logo} alt="logo" />
                        </a>
                        <div className="pt-12 px-4 md:px-16 xl:px-36 text-center text-white w-full font-rubik">
                            <h1 className="text-2xl sm:text-4xl font-bold mr-20">$Beanie PFP Generator</h1>
                        </div>
                    </div>
                </div>

                  {/* <div className="w-1/6 md:w-32 fixed top-44  flex-col hidden md:flex">
                      {capLists.map((item, index) => {
                          return (
                              <img
                                  onClick={() => handleSelectCap(`/caps/${item}`)}
                                  className="hover:blur-0 blur-[0.5px] rounded-md bg-transparent  bg-gray-100 hover:bg-gray-200 w-10/12 h-10/12 mt-4 cursor-pointer"
                                  key={index}
                                  src={`/caps/${item}`}
                              />
                          );
                      })}
                  </div> */}

                <div className="mt-16 sm:flex sm:items-center sm:justify-around text-center">
                    <div className="flex items-center justify-center">
                        <div className="shrink-0"></div>
                        <input type="file" id="file" className="hidden" onChange={handleImageChange} />
                        <label
                            htmlFor="file"
                            className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50"
                        >
                            <div className="px-8 py-4  text-[#0042fe] hover:bg-[#1d1e20] text-xl font-semibold bg-white hover:text-white cursor-pointer font-rubik">Profile Picture</div>
                        </label>
                    </div>
                    {selectedImage && (
                        <div onClick={exportImage} className="px-8 py-4 mt-3 sm:mt-0 text-[#0042fe] hover:bg-[#1d1e20] text-xl font-semibold bg-white hover:text-white cursor-pointer font-rubik">
                            Download
                        </div>
                    )}
                </div>

                <div className="mt-6 md:hidden">
                    <div className=" flex items-center justify-center">
                        {/* {capLists.map((item, index) => {
                            return (
                                <img
                                    onClick={() => handleSelectCap(`/caps/${item}`)}
                                    className="border-2 border-[#96b9ff] hover:blur-0 blur-[0.5px] rounded-md bg-transparent  bg-gray-100 hover:bg-gray-200 w-12 h-12 sm:w-16 sm:h-16 mx-2 cursor-pointer"
                                    key={index}
                                    src={`/caps/${item}`}
                                />
                            );
                        })} */}
                    </div>
                </div>

                <div className="mt-8">
                    <div className={`relative xl:w-[600px] lg:w-[500px] w-[300px] m-auto`}>
                        <div className="flex items-center justify-between mb-6">
                            {/* <div className="m-auto w-full text-center  flex items-center justify-start text-xl font-semibold text-white">
                                {isFit ? <span className="mr-4">Fit Current Aspect Ratio</span> : <span className="mr-4">Original Image Size</span>}
                                <Switch onColor="#004feb" onChange={(e) => setIsFit(e)} checked={isFit} />
                            </div> */}

                            {capLists.map((item, index) => {
                                return (
                                    <img
                                        onClick={() => handleSelectCap(`/caps/${item}`)}
                                        className="border-2 border-[#96b9ff] hover:blur-0 blur-[0.5px] rounded-md bg-transparent  bg-gray-100 hover:bg-gray-200 w-12 h-12 sm:w-16 sm:h-16 mx-2 cursor-pointer"
                                        key={index}
                                        src={`/caps/${item}`}
                                    />
                                );
                            })}
                            <img onClick={remoteHat} className="w-7 transition cursor-pointer" src="/remove.png" />
                            {/* <span  className="text-xl font-semibold text-white cursor-pointer">Remove Hat</span> */}
                        </div>

                        <div ref={containerRef} className={`shadow-box border-4 relative xl:w-[600px] lg:w-[500px] w-[300px] m-auto group h-[${boxHeight}px]`}>
                            <Stage
                                width={dimensions.width}
                                height={dimensions.height}
                                ref={stageRef}
                                onMouseDown={(e) => {
                                    // deselect when clicked on empty area
                                    const clickedOnEmpty = e.target === e.target.getStage();
                                    if (clickedOnEmpty) {
                                        selectShape(null);
                                    }
                                }}
                            >
                                <Layer>
                                    <URLImage key={-1} url={selectedImage} isSelected={false} width={dimensions.width} height={dimensions.height} />
                                    {[selectedCap].map((url, i) => {
                                        return (
                                            <URLImage
                                                key={i}
                                                x={dimensions.width / 3}
                                                url={url}
                                                width={dimensions.width / 5}
                                                height={dimensions.height / 5}
                                                isSelected={i === selectedId}
                                                onSelect={() => {
                                                    selectShape(i);
                                                }}
                                            />
                                        );
                                    })}
                                </Layer>
                            </Stage>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
